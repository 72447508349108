import acpVirtualCardsFaqTemplate from './templates/acp-virtual-cards-faq.html';
import acpVirtualCardsHowToUseTemplate from './templates/acp-virtual-cards-howtouse.html';
// @ngInject

function AcpVirtualCardsBottomLinksComponentCtrl() {}

var acpVirtualCardsBottomLinksComponent = {
  controller: AcpVirtualCardsBottomLinksComponentCtrl,
  controllerAs: 'vm',
  template: [
    '$attrs',
    function($attrs) {
      if ($attrs.mode === 'faq') {
        return acpVirtualCardsFaqTemplate;
      } else {
        return acpVirtualCardsHowToUseTemplate;
      }
    }
  ],
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  require: {}
};

export default acpVirtualCardsBottomLinksComponent;
