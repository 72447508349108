import acpVirtualCardsBottomLinksWrapperTemplate from './templates/acp-virtual-cards-bottom-links-wrapper.html';

// @ngInject
function AcpVirtualCardsBottomLinksWrapperComponentCtrl() {
  var vm = this;
  vm.gaCategory = 'ACP_Virtual_Accounts';
}

var acpVirtualCardsBottomLinksWrapperComponent = {
  controller: AcpVirtualCardsBottomLinksWrapperComponentCtrl,
  controllerAs: 'vm',
  template: acpVirtualCardsBottomLinksWrapperTemplate,
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  require: {}
};

export default acpVirtualCardsBottomLinksWrapperComponent;
